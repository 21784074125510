import img1 from "./glimpses/1.jpg";
import img2 from "./glimpses/2.jpg";
import img3 from "./glimpses/3.jpg";
import img4 from "./glimpses/4.jpg"; 
import img5 from "./glimpses/5.jpg";
import img6 from "./glimpses/6.jpg";
import img7 from "./glimpses/7.jpg";
import img8 from "./glimpses/8.jpg";
import img9 from "./glimpses/9.jpg";
import img10 from "./glimpses/10.jpg";
import img11 from "./glimpses/11.jpg";

export const memData = [
    {
        image: img1,
    },
    {
        image: img2,
    },
    {
        image: img3,
    },
    {
        image: img4,
    },
    {
        image: img5,
    },
    {
        image: img6,
    },
    {
        image: img7,
    },
    {
        image: img8,
    },
    {
        image: img9,
    },
    {
        image: img10,
    },
    {
        image: img11,
    },
]