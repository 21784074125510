import img11 from "./guests/1.png";
import img22 from "./guests/2.png";
import img33 from "./guests/3.png";
import img44 from "./guests/4.png"; 


export const guestData = [
    {
        image: img11,
    },
    {
        image: img22,
    },
    {
        image: img33,
    },
    {
        image: img44,
    }
]