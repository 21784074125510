import img1 from "./photos/hcv.jpeg";
import img2 from "./photos/dcd.jpeg";
import img3 from "./photos/agt.jpeg";
import img4 from "./photos/agy.jpeg"; 
import img5 from "./photos/asg.jpeg";
import img6 from "./photos/asn.jpeg";
import img7 from "./photos/adp.jpeg";
import img8 from "./photos/abb.jpeg";
import img9 from "./photos/jng.jpeg";
import img10 from "./photos/jvn.jpeg";
import img11 from "./photos/mnl.jpeg";
import img12 from "./photos/rdi.jpeg";
import img13 from "./photos/sar.jpeg";
import img14 from "./photos/sdd.jpeg";
import img15 from "./photos/smk.jpeg";
import img16 from "./photos/usn.jpeg";

export const imgData = [
    {
        image: img1,
    },
    {
        image: img2,
    },
    {
        image: img3,
    },
    {
        image: img4,
    },
    {
        image: img5,
    },
    {
        image: img6,
    },
    {
        image: img7,
    },
    {
        image: img8,
    },
    {
        image: img9,
    },
    {
        image: img10,
    },
    {
        image: img11,
    },
    {
        image: img12,
    },
    {
        image: img13,
    },
    {
        image: img14,
    },
    {
        image: img15,
    },
    {
        image: img16,
    },
]