import img1 from "./photos/sudhanshu.jpg";
import img2 from "./photos/rishabh.jpeg";
import img3 from "./photos/rishikesh.png";
import img4 from "./photos/ishat.png";
import img5 from "./photos/yash.jpeg";
import img6 from "./photos/manas.png";
import img7 from "./photos/sameer.jpeg";
import img8 from "./photos/fizaan.jpeg";
import img9 from "./photos/manik.png";
import img10 from "./photos/nischala.jpeg";
import img11 from "./photos/advik.jpeg";
import img12 from "./photos/ashutosh.png";
import img13 from "./photos/ali.jpg";
import img14 from "./photos/ujjwal.png";
import img15 from "./photos/vandit.jpeg";
import img16 from "./photos/eshaan.jpg";
import img17 from "./photos/ishaan.png";
import img18 from "./photos/kanhaiya.jpg";
import img19 from "./photos/mrityunjay.jpg";
import img20 from "./photos/priyanshi.jpeg";
import img21 from "./photos/abhishek.jpg";
import img22 from "./photos/gauhar.jpg";
import img23 from "./photos/biswa.png";


const data = {
  advisors: [
    {
      id: 21,
      img: img21,
      position: "Faculty Convener",
      name: "Dr. Abhishek K. Srivastava",
      insta: "",
      link: "https://www.linkedin.com/in/abhishek-kumar-srivastava-3aa030210/",
      profile: "https://iitbhu.ac.in/dept/phy/people/asrivastavaapp",
    },
    {
      id: 23,
      img: img23,
      position: "Faculty Treasurer",
      name: "Dr. Biswanath Bhoi",
      insta: "",
      link: "https://www.linkedin.com/in/biswanath-bhoi-89643241/",
      profile: "https://iitbhu.ac.in/dept/phy/people/biswanathphy",
    },
    {
      id: 22,
      img: img22,
      position: "Faculty Secretary",
      name: "Dr. Gauhar Abbas",
      insta: "",
      link: "",
      profile: "https://iitbhu.ac.in/dept/phy/people/gauharphy",
      
    },
  ],
  convener: [
    {
      id: 1,
      img: img1,
      position: "Convener",
      name: "Sudhanshu Shrivastava",
      insta: "https://www.instagram.com/sudhanshu.shrivastava.980",
      link: "https://www.linkedin.com/in/sudhanshu-shrivastava-101270210",
      profile: "",
    },
    {
      id: 2,
      img: img2,
      position: "Co-Convener",
      name: "Rishabh Raj",
      insta: "https://www.instagram.com/rishabhraj_ji",
      link: "https://www.linkedin.com/in/rishabh-raj-3374b5226",
      profile: "",
    },
  ],
  tech: [
    {
      id: 3,
      img: img3,
      position: "Tech Head",
      name: "Rishikesh Sahu",
      insta: "https://www.instagram.com/hxzzzz_._._",
      link: "https://www.linkedin.com/in/rishikesh-sahu-0608r03/",
      profile: "",
    },
    {
      id: 4,
      img: img4,
      position: "Tech Manager",
      name: "Ishat Gupta",
      insta: "https://www.instagram.com/ishat_gupta",
      link: "https://www.linkedin.com/in/ishat-gupta",
      profile: "",
    }
  ],
  design: [
    {
      id: 5,
      img: img5,
      position: "Design Head",
      name: "Yash Agarwal",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    },
    {
      id: 6,
      img: img6,
      position: "Design Manager",
      name: "Manas Gupta",
      insta: "https://www.instagram.com/manasgupta_7",
      link: "https://www.linkedin.com/in/guptamanas7",
      profile: "",
    }
  ],
  marketing: [
    {
      id: 7,
      img: img7,
      position: "Marketing Head",
      name: "Sameer Wanjari",
      insta: "https://www.instagram.com/_sameer.w",
      link: "https://www.linkedin.com/in/sameer-wanjari-b52385224",
      profile: "",
    },
  ],
  events: [
    {
      id: 8,
      img: img8,
      position: "Event Head",
      name: "Fizaan Khan",
      insta: "https://www.instagram.com/fazinhan",
      link: "https://www.linkedin.com/in/fizaan-khan-b2750a242",
      profile: "",
    },
    {
      id: 9,
      img: img9,
      position: "Event Head",
      name: "Manik Sharma",
      insta: "https://www.instagram.com/maniksharma1824",
      link: "https://www.linkedin.com/in/manik-sharma-72495b24a",
      profile: "",
    },
    {
      id: 10,
      img: img10,
      position: "Event Manager",
      name: "Nischala Pendyala",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    },
    {
      id: 11,
      img: img11,
      position: "Event Manager",
      name: "Advik Gupta",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    }
  ],
  publicity: [
    {
      id: 12,
      img: img12,
      position: "Publicity Head",
      name: "Ashutosh Baheti",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    },
    {
      id: 13,
      img: img13,
      position: "Publicity Manager",
      name: "Mohammad Ali",
      insta: "https://www.instagram.com/_alien_gramer_111",
      link: "https://www.linkedin.com/in/ali-sobhani-8997b0247",
      profile: "",
    }
  ],
  pr: [
    {
      id: 14,
      img: img14,
      position: "Public Relation Head",
      name: "Ujjwal Kumar",
      insta: "https://www.instagram.com/ujjwalkumar10228",
      link: "https://www.linkedin.com/in/ujjwal-kumar-287831167",
      profile: "",
    },
  ],
  content: [
    {
      id: 15,
      img: img15,
      position: "Content Head",
      name: "Vandit Jain",
      insta: "https://www.instagram.com/_its_me_jv_",
      link: "https://www.linkedin.com/in/vandit-jain-774253248",
      profile: "",
    },
  ],

  executives: [
    {
      id: 16,
      img: img16,
      position: "Executive",
      name: "Eshaan Sugeesh",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    },
    {
      id: 17,
      img: img17,
      position: "Executive",
      name: "Ishaan Goel",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    },
    {
      id: 18,
      img: img18,
      position: "Executive",
      name: "Kanhaiya Gupta",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    },
    {
      id: 19,
      img: img19,
      position: "Executive",
      name: "Mrityunjay Kamlapuri",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    },
    {
      id: 20,
      img: img20,
      position: "Executive",
      name: "Priyanshi Dixit",
      insta: "https://jigyasaiitbhu.in/team/",
      link: "https://jigyasaiitbhu.in/team/",
      profile: "",
    }
  ],
};

export default data;
