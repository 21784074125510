import img1 from "./Images/NebulaNet.png";
import img2 from "./Images/collider.jpg";
// import img3 from "./Images/pita.png";
import img4 from "./Images/showdown.jpg";
import img5 from "./Images/memiphy.png";
import img6 from "./Images/guest.png";
import img7 from "./Images/event.png";

export const Data = [
  {
    title: "NebulaNet",
    information: "Machine Learning tools to classify astronomical objects from telescopic data.",
    link: "https://unstop.com/hackathons/nebulanet-ml-hackathon-jigyasa-24-iit-bhu-947094",
    image: img1,
    slide: "fade-up",
    flag: true,
  },
  {
    title: "Code Collider",
    information: "Hotwire your brain in this awesome workshop of Competitive Programming and show off your skills in the competition.",
    link: "https://unstop.com/hackathons/code-collider-jigyasa-24-iit-bhu-946511",
    image: img2,
    slide: "fade-up",
    flag: true,
  },
  {
    title: "Space-Time Showdown",
    information: "Join us for a thrilling collision of creativity where participants merge scientific principles with innovative game design to craft immersive experiences.",
    link: "https://unstop.com/hackathons/space-time-showdown-jigyasa-24-iit-bhu-947618",
    image: img4,
    slide: "fade-up",
    flag: true,
  },

  {
    title: "AstrOlympics",
    information: "Reach for the stars and conquer the cosmos in the ultimate celestial challenge!",
    link: "https://unstop.com/p/astrolympics-jigyasa-24-iit-bhu-948922",
    image: img7,
    slide: "fade-up",
    flag: true,
  },
  // {
  //   title: "Peek into the Asteroverse",
  //   information: "Explore the night sky with amazing telescopes and awesome cameras.",
  //   link: "https://google.com/",
  //   image: img3,
  //   slide: "fade-up",
  // },
  // {
  //   title: "Quantum leap",
  //   information: "Get your ears and brains together for demystifying the world of quantum computing to get flabbergasted in this mind bobbling workshop of Quantum computing.",
  //   link: "https://unstop.com/college-fests/jigyasa-23-indian-institute-of-technology-banaras-hindu-university-121575",
  //   image: img4,
  //   slide: "fade-up",
  // },
  {
    title: "MemiΦ",
    information: "Make memes on your favorite physics topics and submit it online.",
    link: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfcFOIrZy4US2cIzb6sYzKMP07QqYqQLb0Zsia60vUltDlwVQ/viewform?usp=send_form&pli=1",
    image: img5,
    slide: "fade-up",
    flag: true,
  },
  {
    title: "Integration Bee",
    information: "Solve complex integrations problems with us and get chance to win exciting prizes.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdFgi5r2uB8FpeimsLgO3321VdftQaizy_5_B6u3QDz5y1pOw/viewform",
    image: img7,
    slide: "fade-up",
    flag: true,
  },
  {
    title: "Quantum Gambit",
    information: "Dive into the world of intense chess battles and showcase your strategic prowess at our Chess Event.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScQWTnHlNT81JR-OqE-xDuX-c21hYwDLSx9fQrios2j7ht8Vw/viewform",
    image: img7,
    slide: "fade-up",
    flag: true,
  },
  {
    title: "Guest Lectures",
    information: "Talks by the world renowned scientists working on cutting edge research in the field of Physics.",
    // link: "https://google.com/",
    image: img6,
    slide: "fade-up",
    flag: false,
  },
  // {
  //   title: "Physics quest",
  //   information: "Solve riddles and puzzles in the comfort of lecture theater & win exciting prizes.",
  //   // link: "https://google.com/",
  //   image: img7,
  //   slide: "fade-up",
  //   flag: false,
  // },
];
